.body-content {
  //height: 100%;
}

.layout-side-menu  {
  padding: 10px;

  .ant-typography {
    color: #fff;
    font-weight: bold;
  }
}

.layout-header {
  &.ant-layout-header {
    color: #fff;

    h1 {
      color: #fff;
    }
  }
}

.layout-content {
  padding: 20px;
}

.breadcrumbs {
  margin-bottom: 20px;
}

